.primary {
	/* background-color: #fff; */
	padding: 10px 10px 3px 10px;
	border-bottom: 4px solid #91d5ff;
	/* border-top: 2px solid #91d5ff; */
}

.secondary {
	/* background-color: #ff9c6e; */
	padding: 10px 10px 3px 10px;
	border-bottom: 4px solid #c7a4ef;
	/* border-top: 2px solid #ff9c6e; */
}
.primary-light {
	background-color: #e6f7ff !important;
	border-bottom: 2px solid #e6f7ff;
	padding: 5px 10px 5px 10px;
}

.white-bg {
	background-color: #fff;
}
.dark-bg {
	background-color: #303030 !important;
}

.secondary-light {
	background-color: #efe5fa;
	border-bottom: 2px solid #efe5fa;
	padding: 5px 10px 5px 10px;
}

.dashboard-table-title {
	font-size: 1.2em;
	line-height: 1em;
	display: block;
	padding: 5px 0px;
}
.table-title {
	font-size: 1.2em;
	line-height: 1em;
	display: block;
	padding-bottom: 10px;
}

.text-white {
	color: white;
}
